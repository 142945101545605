import {
  materialPage,
  printingWay,
  productDetails,
  categorys,
  productPage,
  imageConvert,
  alculateProductPrice,
  alculateProductPriceAndMatchDiscount,
  alculateProductDelivery,
  cartAdd,
  getIdByCode,
  productRelated,
  listBestSellers,
  listNewArrival,
  getProductDetailByCode,
  listRecommend,
  getIrregularFlag,
  orderProductSnapshot,
  reportSearch
} from "../services/api/product";

class productInteractor {
  static getInstance() {
    return this._instance;
  }
  static _instance = new productInteractor();

  /**
   * 分页材质
   * @param {categoryId} 类目id
   */
  materialPage(id) {
    return materialPage({
      categoryId: id
    });
  }

  /**
   * 印刷方式
   */
  printingWay() {
    return printingWay();
  }

  /**
   * 产品详情
   * @param {id} 产品id
   */
  productDetails(id) {
    return productDetails(id);
  }

  /**
   * 获取类目列表
   */
  categorysApi() {
    return categorys();
  }

  /**
   * 分页获取产品列表
   * @param {materials} 材质
   * @param {printingWayIds} 印刷方式id
   * @param {priceMin} 产品价格Min
   * @param {priceMax} 产品价格Max
   * @param {quantity} 购买数量
   */
  productPage(params) {
    return productPage(params);
  }

  /**
   * 图片格式转化
   */
  imageConvertApi(data) {
    return imageConvert(data);
  }

  /**
   * 计算产品价格
   */
  alculateProductPriceApi(params) {
    return alculateProductPrice(params);
  }

  alculateProductPriceAndMatchDiscountApi(params) {
    return alculateProductPriceAndMatchDiscount(params);
  }
  /**
   * 计算产品预计到达时间
   */
  alculateProductDeliveryApi(params) {
    return alculateProductDelivery(params);
  }

  /**
   * 添加购物车
   */
  cartAddApi(params) {
    return cartAdd(params);
  }

  /**
   * 获取产品ID
   * @param {code} 产品code
   */
  getIdByCodeApi(code) {
    return getIdByCode({ code: code });
  }

  /**
   * 相关产品
   * @param {id}
   */
  productRelatedApi(id) {
    return productRelated(id);
  }

  //获取销量好的产品列表
  async listBestSellersApi() {
    let res = await listBestSellers();
    return res;
  }

  // 获取新品
  async listNewArrivalApi() {
    let res = await listNewArrival();
    return res;
  }

  async getProductDetailByCodeApi(code) {
    let res = await getProductDetailByCode(code);
    return res;
  }
  async listRecommendApi() {
    let res = await listRecommend();
    return res;
  }

  /**
   * 获取产品不规则标记
   * @param {id} 产品id
   */
  getIrregularFlagApi(id) {
    return getIrregularFlag(id);
  }

  /**
   * 产品详情
   * @param {id} 产品id
   */
  orderProductSnapshot(data) {
    let { orderProductId } = data;
    return orderProductSnapshot({ orderProductId });
  }

  // 搜索辅助谷歌埋点上报日志记录
  reportSearchApi(query) {
    const { keyWord, type, source, id, method, version='' } = query
    return reportSearch({
      keyWord,  // 搜索关键字
      type,     // 1:搜索  2:搜索成功 3:搜索有结果
      source,   // 1:搜索点击 2:复制连接
      id,       // 一对请求回调的id一样
      method,   // request  或  callback
      version   // 版本号
    });
  }
}

export default productInteractor.getInstance();
