export const useDesignStore = defineStore("design", {
  state() {
    return {
      prints: [],
      extendsInfo: [],
      removeBackground: false,
      backgroundHandling: false, // 去背景处理中...
      cartInfo: {
        productId: "",
        productPicUrl: "",
        productPrinting: {
          pricingMethod: "",
          printingColors: "",
          printingWay: "",
          printingWayId: "",
          printingWayType: "",
          printingLocations: [],
          printingSurfaces: []
        },
        productSpecs: "",
        quantity: "",
        sampleFlag: "",
        specsType: "",
        specsValue: ""
      },
      calculatePriceParams: null,
      priceInfo: {},
      fromPageName: "",
      fontColor: "#000000",
      imageSize: {
        width: 0,
        height: 0
      }
    };
  },

  actions: {
    setPrints(prints) {
      this.prints = prints;
    },

    setRemoveBackground(isRemoveBackground) {
      this.removeBackground = isRemoveBackground;
    },

    setBackgroundHandling(isBackgroundHandling) {
      this.backgroundHandling = isBackgroundHandling;
    },

    // 产品详情页在点击Design now时组装好添加购物车相关参数
    setCartInfo(cartInfo) {
      this.cartInfo = cartInfo;
    },

    setCalculatePriceParams(calculatePriceParams) {
      this.calculatePriceParams = calculatePriceParams;
    },

    setExtendsInfo(extendsInfo) {
      this.extendsInfo = extendsInfo;
    },

    setPriceInfo(priceInfo) {
      this.priceInfo = priceInfo;
    },

    setFromPageName(fromPageName) {
      this.fromPageName = fromPageName;
    },

    setFontColor(fontColor) {
      this.fontColor = fontColor;
    },

    setImageSize(imageSize) {
      this.imageSize = imageSize;
    }
  },
  persist: {
    // omit 功能不知为何失效
    // docs: https://prazdevs.github.io/pinia-plugin-persistedstate/guide/config#omit
    omit: ['imageSize']
  }
});
